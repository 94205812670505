import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="test-0-phone">&#9990;  8 (800) 289-20-10</div>

        </footer>
    );
};

export default Footer;
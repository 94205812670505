import React from 'react';

const Download = () => {
    return (
        <div id={'download'}>
            <div id={'content'}>
                <p className={'color_white'}>Идет загрузка....</p>
                <p id={'rotate'} className={'color_white'}>&#8635;</p>

            </div>

        </div>
    );
};

export default Download;